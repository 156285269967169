@import "config";

.story {
  &-item {
    max-width: 105px;
    width: 100%;
    min-height: 124px;
    flex-shrink: 0;
    border-radius: 9px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    margin: 1px 0;

    @media (max-width: 360px) {
      max-width: 100px;
      min-height: 121px;
    }

    @media (max-width: 320px) {
      max-width: 87px;
      min-height: 106px;
    }

    &._new {
      box-shadow: 0 0 0 1px #fff, 0 0 0 3px #FF97B9;
    }

    & + .story-item {
      margin-left: 10px;
    }

    &__title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 8px 10px;
      overflow: hidden;
      overflow-wrap: break-word;
      font-family: var(--override-font-value-serif-medium, $font_value_serif_medium);
      font-weight: 400;
      font-size: 13px;
      color: #fff;
      line-height: 15px;
    }
  }

  &-navigation-bar {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
  }

  &-progress {
    width: 100%;
    display: flex;
    padding: calc(10px + var(--offset-top)) 24px;
    pointer-events: none;

    &__item {
      height: 2px;
      flex-grow: 1;
      background: rgba(255, 255, 255, 0.5);
      margin: 0 1px;
      overflow: hidden;
      position: relative;

      div {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: #fff;
      }

      &._active {
        div {
          transition: width .1s linear;
          background: #fff;
        }
      }

      &._passed {
        div {
          width: 100% !important;
        }
      }
    }
  }

  &__close {
    top: calc(26px + var(--offset-top));
    right: 24px;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 44px;
    position: absolute;
    z-index: 4;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 17px;
      height: 2px;
      background: #fff;
      pointer-events: none;
      top: 50%;
      left: 50%;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &__control {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 5;

    &._prev {
      left: 0;
    }

    &._next {
      right: 0;
    }
  }

  &__slide {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  &__btn-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px calc(16px + var(--offset-bottom));
    font-family: var(--override-font-feixen, $font_feixen);
    font-weight: 700;
    z-index: 10;

    .stories-action-button {
      line-height: 22px;
      font-weight: 500;
      width: 100%;
      text-align: center;
    }

    div {
      pointer-events: auto;
    }
  }

  &__slider {
    .swiper-wrapper {
      z-index: 6;
      pointer-events: none;
    }
  }
}
