$icon-chat: "\e945";
$icon-jiffy-point-check: "\e943";
$icon-jiffy-point: "\e944";
$icon-share: "\e942";
$icon-spark: "\e941";
$icon-clock: "\e93f";
$icon-sleep: "\e940";
$icon-label: "\e93d";
$icon-cart: "\e93e";
$icon-sleep-outline: "\e93b";
$icon-flash-outline: "\e93c";
$icon-clock-outline: "\e93a";
$icon-arrow-long: "\e939";
$icon-percent: "\e938";
$icon-discount: "\e936";
$icon-blocked: "\e933";
$icon-accepted: "\e935";
$icon-picking: "\e934";
$icon-payment-card: "\e932";
$icon-exclamation: "\e931";
$icon-sign-i: "\e937";
$icon-indelivery: "\e930";
$icon-tornado: "\e92f";
$icon-star-rounded: "\e92e";
$icon-pen: "\e92d";
$icon-info: "\e92c";
$icon-attach: "\e92b";
$icon-plane: "\e92a";
$icon-check-long: "\e929";
$icon-star: "\e928";
$icon-stopwatch: "\e927";
$icon-check-circle: "\e926";
$icon-delivery: "\e925";
$icon-star-fill: "\e924";
$icon-fire: "\e923";
$icon-flag: "\e922";
$icon-favorite-fill: "\e921";
$icon-remove: "\e920";
$icon-attention: "\e91f";
$icon-card: "\e91e";
$icon-security: "\e91d";
$icon-check-box: "\e91c";
$icon-error: "\e91b";
$icon-bag: "\e91a";
$icon-chevron-right: "\e919";
$icon-chevron-right-thin: "\e918";
$icon-chevron-down: "\e917";
$icon-profile: "\e916";
$icon-basket: "\e915";
$icon-support: "\e914";
$icon-faq: "\e913";
$icon-about: "\e912";
$icon-bell: "\e911";
$icon-gear: "\e910";
$icon-mail: "\e90f";
$icon-filter: "\e90e";
$icon-heart: "\e90d";
$icon-heart-fill: "\e90c";
$icon-minus: "\e90b";
$icon-plus: "\e90a";
$icon-check: "\e909";
$icon-close: "\e908";
$icon-arrow-back: "\e907";
$icon-flash: "\e906";
$icon-search: "\e905";
$icon-favorite: "\e904";
$icon-geolocation: "\e903";
$icon-geolocation-fill: "\e902";
$icon-burger: "\e901";
$icon-marker: "\e900";
$icon-jiffy-point-check: "\e943";
$icon-jiffy-point: "\e944";
$icon-chat: "\e945";
$icon-fav: "\e946";
