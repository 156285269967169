$img_dir: "../img/";
$fonts_dir: "../fonts/";

$font_feixen: "Studio Feixen Sans", sans-serif;
$font_feixen_book: "Studio Feixen Sans Book", sans-serif;
$font_value_serif_medium: "Value Serif Medium", sans-serif;
$font_arial: "Arial", sans-serif;
$font_icomoon: "icomoon" !important;

$colors: (
  "white": #fff,
  "black": #000,
  "primary": #ffb000,
  "bg": #f5f4f2,
  "text": #333,
  "t99": #999,
  "tad": #adadad,
  "teb": #ebebeb,
  "tc7": #c7c7c7,
  "tf7": #f7f7f7,
  "tf2": #f2f2f2,
  "tc4": #c4c4c4,
  "yellow": #ffbc41,
  "lyellow": #f8cd46,
  "ulyellow": #ffebbf,
  "backyellow": #fef1e7,
  "gray": #878786,
  "mgray": #999fa4,
  "dgray": #71797f,
  "sgray": #f4f3f3,
  "udgray": #333f48,
  "fgray": #4f4f4f,
  "efgray": #efeff0,
  "ngray": #a9562a,
  "lgray": #c2c1bd,
  "lsgray": #7da9b3,
  "ftgray": #a17d57,
  "linegray": #ebeced,
  "salad": #45af8f,
  "salad2": #56cb82,
  "salad3": #66ba48,
  "salad4": #bdE9c9,
  "green": #80c557,
  "lgreen": #aadb1e,
  "ulgreen": #d0df00,
  "mulgreen": #f9fdfb,
  "mgreen": #7cad5f,
  "vgreen": #017f00,
  "backgreen": #e7f7ed,
  "tgreen": #0eb44d,
  "dgreen": #24594b,
  "lpurple": #af7cff,
  "red": #e5243f,
  "nred": #e96b78,
  "cred": #eb5757,
  "ulred": #fce9ec,
  "sblue": #6aa7bf,
  "blue": #0091da,
  "orange": #f97313,
  "dorange": #e35531,
  "lorange": #ef9500,
  "corange": #f44719,
  "pink": #fb48ff,
  "semantic-success": #87D9A6,
  "semantic-error": #F2919F,
  "primaryGray": #EFEFF0
);

$layout_max_width: 500px;
$iphone_11_pro_max_width: 414px;
$iphone_se_5_width: 320px;

$zindex_popover: 220;
$zindex_alert: 120;
$zindex_main-header: 61;
$zindex_scroll_top: 60;
$zindex_cart_board: 50;

@mixin icomoonInit {
  font-family: $font_icomoon;
  flex-shrink: 0;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  line-height: 1;
}
