@import "config";

@font-face {
  font-family: "icomoon";
  src: url($fonts_dir + "icomoon.ttf") format("truetype"),
  url($fonts_dir + "icomoon.woff") format("woff"),
  url($fonts_dir + "icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Value Serif Medium";
  src: url($fonts_dir + "value-serif-medium.woff2") format("woff2"),
  url($fonts_dir + "value-serif-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Studio Feixen Sans";
  src: url($fonts_dir + "StudioFeixenSans-Regular.woff2") format("woff2"),
  url($fonts_dir + "StudioFeixenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Studio Feixen Sans";
  src: url($fonts_dir + "StudioFeixenSans-Bold.woff2") format("woff2"),
  url($fonts_dir + "StudioFeixenSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: "Studio Feixen Sans";
  src: url($fonts_dir + "StudioFeixenSans-Medium.woff2") format("woff2"),
  url($fonts_dir + "StudioFeixenSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Studio Feixen Sans Book";
  src: url($fonts_dir + "StudioFeixenSans-Book.woff2") format("woff2"),
  url($fonts_dir + "StudioFeixenSans-Book.woff") format("woff");
}
