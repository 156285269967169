html.company-swifft {
  &:root {
    --override-font-feixen: "Arial", sans-serif;
    --override-font-feixen-book: "Arial", sans-serif;
    --override-font-value-serif-medium: "Arial", sans-serif;
  }

  .button._primary,
  .counter-add,
  .promocode__btn,
  .counter-wrap._discount .counter-add {
    background: #26a9e1;
    color: #fff;
  }

  .counter-add {
    &._sold-out {
      background: none !important;
      color: #000 !important;
    }
  }


  .status-widget {
    background: #26a9e1;
    color: #fff !important;
  }

  .input-switch._active {
    background: #26a9e1;
  }

  .product-item__discount:before,
  .counter-wrap._discount .counter__icon {
    color: #26a9e1;
  }

  .product-item__price._discount {
    color: #000;
  }

  .product-item__price-unit,
  .product-item__price-old {
    color: #8c8b8b;
  }

  .promocode._filled {
    border-color: #26a9e1;
  }

  .counter,
  .counter-wrap._discount .counter {
    border-color: #26a9e1;
  }

  .counter__icon {
    color: #26a9e1;
  }

  .checkout-instruction:before {
    background: #26a9e1;
  }

  .checkout-instruction__check {
    background: #26a9e1;
    color: #fff !important;
  }

  .product-item__favorite.c-tc7 {
    color: #8c8b8b !important;
  }

  .product-item__bakery {
    color: #000;
  }

  .product-item__trademark:before,
  .radio-bullet {
    color: #26a9e1;
  }

  .radio-bullet._active {
    border-color: #26a9e1;
  }

  .input-checkbox._active {
    background: #26a9e1;
    border-color: #26a9e1;
  }

  .input-checkbox .icon {
    color: #fff;
  }

  .button._secondary {
    background: #f3f3f3;
  }

  .c-bg-dgreen,
  .c-bg-dorange {
    background: #26a9e1 !important;
  }

  .status-icon._active {
    background: #26a9e1;
  }

  .status-icon {
    color: #8c8b8b;
  }

  .icon.c-blue,
  .icon.c-dgreen,
  .c-corange {
    color: #26a9e1 !important;
  }

  .c-bg-corange {
    background-color: #26a9e1 !important;
  }

  .main-header__zip .icon-chevron-right {
    margin-top: -3px;
  }

  .cart-board {
    color: #000;

    .c-dorange {
      color: #8c8b8b !important;
    }
  }

  .page-header {
    &__title {
      font-weight: 600;
    }
  }

  .story {
    &-item {
      &._new {
        box-shadow: 0 0 0 1px #fff, 0 0 0 3px #26a9e1;
      }
    }
  }

  .product {
    &-card {
      .product-item__price {
        font-weight: 600;
      }
    }
  }

  .input-text__icon._check {
    color: #26a9e1;
  }

  .category-title,
  .fw-possibly-bold {
    font-weight: 600 !important;
  }

  .orders-page__illustration {
    width: 80%;
  }

  .referral-code-box {
    border-color: #26a9e1;
  }

  .c-bg-salad4 {
    background-color: #9ed4f0 !important;
  }
}
