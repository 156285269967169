@use "sass:math";
@import "config";

.d {
  &-none {
    display: none !important;
  }

  &-flex {
    display: flex !important;
  }

  &-block {
    display: block !important;
  }

  &-inline {
    display: inline !important;

    &-block {
      display: inline-block !important;
    }

    &-flex {
      display: inline-flex !important;
    }
  }
}

.flex {
  &-center {
    align-items: center !important;
    justify-content: center !important;
  }

  &-shrink {
    &-0 {
      flex-shrink: 0 !important;
    }

    &-1 {
      flex-shrink: 1 !important;
    }
  }

  &-grow {
    &-1 {
      flex-grow: 1;
    }
  }

  &-direction {
    &-column {
      flex-direction: column !important;
    }

    &-row {
      flex-direction: row !important;
    }
  }

  &-wrap {
    flex-wrap: wrap !important;
  }

  &-gap {
    $sizes: 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 50;

    @each $size in $sizes {
      &-#{$size} {
        gap: #{$size}px !important;
      }
    }
  }
}

.align-items {
  &-start {
    align-items: flex-start !important;
  }

  &-baseline {
    align-items: baseline !important;
  }

  &-center {
    align-items: center !important;
  }

  &-end {
    align-items: flex-end !important;
  }
}

.align-self {
  &-start {
    align-self: flex-start !important;
  }

  &-center {
    align-self: center;
  }

  &-end {
    align-self: flex-end !important;
  }
}

.justify-content {
  &-between {
    justify-content: space-between !important;
  }

  &-center {
    justify-content: center !important;
  }

  &-end {
    justify-content: flex-end !important;
  }

  &-start {
    justify-content: flex-start !important;
  }
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.text {
  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }

  &-top {
    vertical-align: top !important;
  }

  &-truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-nowrap {
    white-space: nowrap !important;
  }

  &-wrap {
    white-space: normal !important;
  }

  &-underline {
    text-decoration: underline !important;
  }

  &-capitalize {
    text-transform: capitalize !important;
  }

  &-break-word {
    overflow-wrap: break-word !important;
  }

  &-break-line {
    line-break: anywhere !important;
  }

  &-start {
    text-align: start;
  }
  
  &-end {
    text-align: end;
  }
}

.c {
  @each $name, $color in $colors {
    &-#{$name} {
      color: $color !important;
    }

    &-bg-#{$name} {
      background-color: $color !important;
    }
  }

  &-bg-none {
    background: none !important;
  }

  &-cc {
    color: currentColor !important;
  }
}

.f {
  &s {
    $sizes: 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 28, 32, 35, 36, 40;

    @each $size in $sizes {
      &-#{$size} {
        font-size: #{$size}px !important;
      }
    }
  }

  &w {
    &-400 {
      font-weight: 400 !important;
    }

    &-500 {
      font-weight: 500 !important;
    }

    &-600 {
      font-weight: 600 !important;
    }

    &-700 {
      font-weight: 700 !important;
    }
  }

  &f {
    &-feixen {
      font-family: var(--override-font-feixen, $font_feixen) !important;
    }

    &-feixen-book {
      font-family: var(--override-font-feixen-book, $font_feixen_book) !important;
    }

    &-value {
      font-family: var(--override-font-value-serif-medium, $font_value_serif_medium) !important;
    }
  }
}

.l {
  &h {
    $sizes: 11, 14, 16, 18, 19, 20, 22, 24, 25, 28, 30, 36, 40;

    @each $size in $sizes {
      &-#{$size} {
        @if $size == 1 {
          line-height: #{$size} !important;
        } @else {
          line-height: #{$size}px !important;
        }
      }
    }

    &-1 {
      line-height: 1 !important;
    }
  }

  &-0 {
    left: 0 !important;
  }
}

.t {
  &-0 {
    top: 0 !important;
  }
}

.r {
  $sizes: 11, 0;

  @each $size in $sizes {
    &-#{$size} {
      right: #{$size}px !important;
    }
  }
}

.s {
  $sizes: 12, 14, 17, 18, 19, 20, 22, 24, 28, 32, 35, 40, 42, 44, 50, 60;

  @each $size in $sizes {
    &-#{$size} {
      width: #{$size}px !important;
      height: #{$size}px !important;
      flex-shrink: 0;
    }
  }
}

.w {
  $sizes: 1, 38, 44, 50, 65, 70, 85, 100, 120, 150, 160, 180, 200, 215, 230, 280, 290, 312;

  @each $size in $sizes {
    &-#{$size} {
      width: #{$size}px !important;
    }
  }

  $sizes: 25, 30, 40, 50, 60, 75, 100;

  @each $size in $sizes {
    &-#{$size}p {
      width: #{$size} + unquote("%") !important;
    }
  }

  $sizes: 50, 110, 250, 290, 295, 310;

  @each $size in $sizes {
    &-max-#{$size} {
      max-width: #{$size}px !important;
    }
  }

  $sizes: 50;

  @each $size in $sizes {
    &-min-#{$size} {
      min-width: #{$size}px !important;
    }
  }

  $sizes: 45, 50, 100;

  @each $size in $sizes {
    &-max-#{$size}p {
      max-width: #{$size} + unquote("%") !important;
    }
  }

  &-max-100p {
    max-width: 100% !important;
  }
}

.h {
  $sizes: 1, 3, 4, 8, 9, 12, 16, 18, 20, 22, 24, 28, 30, 32, 36, 40, 42, 44, 48, 50, 75, 95, 130, 150, 176, 200, 450;

  @each $size in $sizes {
    &-#{$size} {
      height: #{$size}px !important;
    }
  }

  $sizes: 1, 48, 120, 124, 150, 200;

  @each $size in $sizes {
    &-min-#{$size} {
      min-height: #{$size}px !important;
    }
  }

  $sizes: 75, 80, 100;

  @each $size in $sizes {
    &-#{$size}p {
      height: #{$size} + "%" !important;
    }
  }

  &-max-100p {
    max-height: 100% !important;
  }

  &-auto {
    height: auto !important;
  }
}

.m {
  $sizes: 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 18, 20, 22, 24, 30, 32, 35, 40, 43, 50, 100, 0;

  @each $size in $sizes {
    &-#{$size} {
      margin: #{$size}px !important;
    }

    &t-#{$size} {
      margin-top: #{$size}px !important;
    }

    &t-n#{$size} {
      margin-top: -#{$size}px !important;
    }

    &b-#{$size} {
      margin-bottom: #{$size}px !important;
    }

    &b-n#{$size} {
      margin-bottom: -#{$size}px !important;
    }

    &l-#{$size} {
      margin-left: #{$size}px !important;
    }

    &l-n#{$size} {
      margin-left: -#{$size}px !important;
    }

    &r-#{$size} {
      margin-right: #{$size}px !important;
    }

    &x-#{$size} {
      margin-left: #{$size}px !important;
      margin-right: #{$size}px !important;
    }

    &x-n#{$size} {
      margin-left: -#{$size}px !important;
      margin-right: -#{$size}px !important;
    }

    &y-#{$size} {
      margin-top: #{$size}px !important;
      margin-bottom: #{$size}px !important;
    }

    &ie-#{$size} {
      margin-inline-end: #{$size}px !important;
    }
    
    &is-#{$size} {
      margin-inline-start: #{$size}px !important;
    }
  }

  &t-auto {
    margin-top: auto !important;
  }

  &b-auto {
    margin-bottom: auto !important;
  }

  &l-auto {
    margin-left: auto !important;
  }

  &x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  &y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  &ie-auto {
    margin-inline-end: auto !important;
  }
  
  &is-auto {
    margin-inline-start: auto !important;
  }
}

.p {
  $sizes: 2, 3, 4, 5, 7, 8, 10, 11, 12, 13, 14, 15, 16, 18, 20, 24, 28, 32, 40, 48, 50, 54, 60, 70, 100, 0;

  @each $size in $sizes {
    &-#{$size} {
      padding: #{$size}px !important;
    }

    &t-#{$size} {
      padding-top: #{$size}px !important;
    }

    &b-#{$size} {
      padding-bottom: #{$size}px !important;
    }

    &l-#{$size} {
      padding-left: #{$size}px !important;
    }

    &r-#{$size} {
      padding-right: #{$size}px !important;
    }

    &x-#{$size} {
      padding-left: #{$size}px !important;
      padding-right: #{$size}px !important;
    }

    &y-#{$size} {
      padding-top: #{$size}px !important;
      padding-bottom: #{$size}px !important;
    }

    &ie-#{$size} {
      padding-inline-end: #{$size}px !important;
    }
    
    &is-#{$size} {
      padding-inline-start: #{$size}px !important;
    }
  }

  &t-100p {
    padding-top: 100% !important;
  }
}

.z {
  $sizes: 0, 1, 2, 3, 4;

  @each $size in $sizes {
    &-#{$size} {
      z-index: #{$size} !important;
    }
  }

  &-auto {
    z-index: auto !important;
  }
}

.o {
  $sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9;

  @each $size in $sizes {
    &-#{$size} {
      opacity: math.div($size, 10) !important;
    }
  }
}

.position {
  &-fixed {
    position: fixed !important;
  }

  &-absolute {
    position: absolute !important;
  }

  &-relative {
    position: relative !important;
  }

  &-sticky {
    position: sticky !important;
  }

  &-unset {
    position: unset !important;
  }
}

.overflow {
  &-hidden {
    overflow: hidden !important;
  }

  &-auto {
    overflow: auto !important;
  }
}

.pe {
  &-n {
    pointer-events: none !important;
  }
}

.br {
  $sizes: 2, 3, 5, 8, 13, 15, 22, 30;

  @each $size in $sizes {
    &-#{$size} {
      border-radius: #{$size}px !important;
    }

    &-tl-#{$size} {
      border-top-left-radius: #{$size}px !important;
    }

    &-tr-#{$size} {
      border-top-right-radius: #{$size}px !important;
    }

    &-bl-#{$size} {
      border-bottom-left-radius: #{$size}px !important;
    }

    &-br-#{$size} {
      border-bottom-right-radius: #{$size}px !important;
    }
  }
}

.sm {
  $sizes: 12, 14, 16;

  @each $size in $sizes {
    &t-#{$size} {
      .sm-item + .sm-item {
        margin-top: #{$size}px !important;
      }
    }
  }
}

.rounded {
  &-circle {
    border-radius: 50% !important;
  }
}

.border {
  @each $name, $color in $colors {
    &-#{$name} {
      border: 1px solid $color !important;
    }

    &-shadow-#{$name} {
      box-shadow: 0 0 0 1px $color inset !important;
    }
  }

  &-0 {
    border: 0 !important;
  }
}

.shadow {
  &-1 {
    box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);
  }

  &-none {
    box-shadow: none !important;
  }
}

.transition {
  &-none {
    transition: none !important;

    &:before,
    &:after {
      transition: none !important;
    }
  }
}

.gradient {
  &-1 {
    background: linear-gradient(90deg, #0091da 0%, #00dacd 135.29%);
  }

  &-2 {
    background: linear-gradient(299.96deg, #acea82 -1.96%, #86cd94 19.47%, #58aeaa 47.67%, #3999c4 74.74%, #3790cb 106.32%);
  }

  &-3 {
    background: linear-gradient(93.34deg, #8cc464 0%, #6db181 95.14%);
  }

  &-animate-1 {
    background: linear-gradient(90deg, #0091da, #00dacd, #0091da, #00dacd);
    background-size: 300% 100%;
    animation: gradient 3s linear infinite;
  }

  &-animate-2 {
    background: linear-gradient(90deg, #e74e66, #fd9dac, #e74e66, #fd9dac);
    background-size: 300% 100%;
    animation: gradient 3s linear infinite;
  }
}

.hide {
  &-first-child {
    &:first-child {
      display: none !important;
    }
  }

  &-last-child {
    &:last-child {
      display: none !important;
    }
  }
}


.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
